import axios from '@/http/axios/axiosPartner';
import auth from './auth';
import address from './address';
import discount from './discount';
import profile from './profile';
import news from './news';
import contacts from './contacts';
import report from './report';

export default {
  namespaced: true,
  actions: {
    GET_INN_OPTIONS({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`find-org/?q=${data.search}`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_VERIFY({ commit }, number) {
      return new Promise((resolve, reject) => {
        axios
          .get(`verify/${number}`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_STATUS() {
      return new Promise((resolve, reject) => {
        axios
          .get('me/profile/status')
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_PAYMENT() {
      return new Promise((resolve, reject) => {
        axios
          .get('me/profile/payment-type')
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_SERVICE() {
      return new Promise((resolve, reject) => {
        axios
          .get('me/profile/service')
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_CATEGORIES() {
      return new Promise((resolve, reject) => {
        axios
          .get('/me/profile/category')
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_REGIONS({ commit }, region) {
      return new Promise((resolve, reject) => {
        axios
          .get(`region/?region=${region}`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_FAQS() {
      return new Promise((resolve, reject) => {
        axios
          .get('faq/')
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    POST_DISCOUNT({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('discount-log/', payload)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
  },
  modules: {
    auth,
    address,
    discount,
    profile,
    news,
    contacts,
    report,
  }
};

