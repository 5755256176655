import axios from '@/http/axios/axiosAdmin';

export default {
  namespaced: true,
  state: () => ({
    cards: [],
    limit: 20,
    status: '2',
    statusName: 'Оплачены',
    count: 1,
    page: 1,
    all: 0,
    length: 0,
  }),
  mutations: {
    setList(state, payload) {
      state.cards = payload;
    },
    changeLimit(state, payload) {
      state.limit = payload;
    },
    changeStatus(state, payload) {
      state.status = payload;
    },
    changeStatusName(state, payload) {
      state.statusName = payload;
    },
    changeCount(state, payload) {
      state.count = payload;
    },
    changePage(state, payload) {
      state.page = payload;
    },
    setAll(state, payload) {
      state.all = payload;
    },
    setLength(state, payload) {
      state.length = payload;
    },
  },
  actions: {
    GET_LIST({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`card-request/?page_size=${data.limit}&page=${data.page}&status=${data.statusCount}&ordering=${data.ordering}`)
          .then(response => {
            commit('setList', response.data.results);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_DATA({ commit }, hash) {
      return new Promise((resolve, reject) => {
        axios
          .get(`card-request/${hash}`)
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
    PATCH_DATA({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`card-request/update/${payload.hash}`, payload.data)
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_QUERY({ commit }, query) {
      return new Promise((resolve, reject) => {
        axios
          .get(`card-request/?query=${query}`)
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_MAIL({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`card-request/email/${payload.hash}`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_TARIFFS() {
      return new Promise((resolve, reject) => {
        axios
          .get('card-request/tariff/')
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_REGIONS({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`region/?region=${data.search}`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_ADDRESS({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`address/?q=${data.search}`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
  }
};