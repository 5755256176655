import Vue from 'vue';
import VueRouter from 'vue-router';
import Admin from '@/views/admin/Index';
import Partner from '@/views/partner/Index';
import Card from '@/views/public/card/Index';
import Discounts from '@/views/public/discounts/Index';

Vue.use(VueRouter);

const routes = [
  {
    path: '/admin',
    component: Admin,
    children: [
      {
        path: '',
        redirect: {name: 'admin'}
      },
      // {
      //   path: 'company/list',
      //   name: 'admin',
      //   component: () => import('@/views/admin/company/List'),
      //   meta: {
      //     title: 'Список партнеров - Международный Клуб Привилегий РСМ'
      //   },
      //   props: {pageTitle: 'Список партнеров'}
      // },
      {
        path: 'company/list/new',
        name: 'admin',
        component: () => import('@/views/admin/company/NewList'),
        meta: {
          title: 'Список партнеров - Международный Клуб Привилегий РСМ'
        },
        props: {pageTitle: 'Список партнеров'}
      },
      {
        path: 'company/list/active',
        name: 'admin2',
        component: () => import('@/views/admin/company/ActiveList'),
        meta: {
          title: 'Список партнеров - Международный Клуб Привилегий РСМ'
        },
        props: {pageTitle: 'Список партнеров'}
      },
      {
        path: 'company/list/review',
        name: 'admin4',
        component: () => import('@/views/admin/company/ReviewList'),
        meta: {
          title: 'Список партнеров - Международный Клуб Привилегий РСМ'
        },
        props: {pageTitle: 'Список партнеров'}
      },
      {
        path: 'company/list/archive',
        name: 'admin3',
        component: () => import('@/views/admin/company/ArchiveList'),
        meta: {
          title: 'Список партнеров - Международный Клуб Привилегий РСМ'
        },
        props: {pageTitle: 'Список партнеров'}
      },
      {
        path: 'person',
        name: 'admin-person',
        component: () => import('@/views/admin/Person'),
        meta: {
          title: 'Профиль пользователя - Международный Клуб Привилегий РСМ'
        },
      },
      {
        path: 'request',
        name: 'request-list',
        component: () => import('@/views/admin/request/List'),
        meta: {
          title: 'Заявки на выпуск карты - Международный Клуб Привилегий РСМ'
        },
      },
      {
        path: 'request/:hash',
        name: 'request',
        component: () => import('@/views/admin/request/Detail'),
        meta: {
          title: 'Редактирование заявки на выпуск карты - Международный Клуб Привилегий РСМ'
        },
      },
      {
        path: 'cards',
        name: 'cards',
        component: () => import('@/views/admin/CardList'),
        meta: {
          title: 'Выпущенные карты - Международный Клуб Привилегий РСМ'
        },
      },
      {
        path: 'cards-free',
        redirect: 'cards-free/import',
      },
      {
        path: 'cards-free/import',
        name: 'cards-free-import',
        component: () => import('@/views/admin/cards-free/Import'),
        meta: {
          title: 'Импорт свободных номеров EYCA - Международный Клуб Привилегий РСМ'
        }
      },
      {
        path: 'cards-free/export',
        name: 'cards-free-export',
        component: () => import('@/views/admin/cards-free/Export'),
        meta: {
          title: 'Экспорт свободных номеров EYCA - Международный Клуб Привилегий РСМ'
        }
      },
      {
        path: 'cards-gpb',
        name: 'cards-gpb',
        component: () => import('@/views/admin/cards-gpb/List.vue'),
        meta: {
          title: 'Карты Газпромбанк - Международный Клуб Привилегий РСМ'
        },
      },
      {
        path: 'cards-gpb/import',
        name: 'cards-gpb-import',
        component: () => import('@/views/admin/cards-gpb/Import.vue'),
        meta: {
          title: 'Импорт карт Газпромбанка - Международный Клуб Привилегий РСМ'
        },
      },
      {
        path: 'cards-gpb/universities',
        name: 'universities',
        component: () => import('@/views/admin/cards-gpb/Universities.vue'),
        meta: {
          title: 'Университеты карт Газпромбанка - Международный Клуб Привилегий РСМ'
        },
      },
      {
        path: 'cards-gpb/requests',
        name: 'requests-gpb',
        component: () => import('@/views/admin/cards-gpb/Requests'),
        meta: {
          title: 'Заявки на карты Газпромбанка - Международный Клуб Привилегий РСМ'
        },
      },
      {
        path: 'cards-gpb/requests/:id',
        name: 'request-gpb',
        component: () => import('@/views/admin/cards-gpb/Request'),
        meta: {
          title: 'Заявка на карту Газпромбанка - Международный Клуб Привилегий РСМ'
        },
      },
      {
        path: 'cards-gpb/events',
        name: 'events-gpb',
        component: () => import('@/views/admin/cards-gpb/Events'),
        meta: {
          title: 'Мероприятия - Международный Клуб Привилегий РСМ'
        },
      },
      {
        path: 'cards-gpb/events/:id',
        name: 'event-gpb',
        component: () => import('@/views/admin/cards-gpb/Event'),
        meta: {
          title: 'Мероприятие - Международный Клуб Привилегий РСМ'
        },
      },
      {
        path: 'users/create',
        name: 'admin-create',
        component: () => import('@/views/admin/manager/Create.vue'),
        meta: {
          title: 'Добавление пользователя - Международный Клуб Привилегий РСМ'
        },
      },
      {
        path: 'users',
        name: 'admin-users',
        component: () => import('@/views/admin/manager/List.vue'),
        meta: {
          title: 'Пользователи - Международный Клуб Привилегий РСМ'
        },
      },
      {
        path: 'users/:id',
        name: 'admin-user',
        component: () => import('@/views/admin/manager/Detail.vue'),
        meta: {
          title: 'Редактирование пользователя - Международный Клуб Привилегий РСМ'
        },
      },
      {
        path: 'statistics',
        name: 'statistics',
        component: () => import('@/views/admin/Statistics.vue'),
        meta: {
          title: 'Статистика - Международный Клуб Привилегий РСМ'
        },
        props: {pageTitle: 'Статистика'}
      },
      {
        path: 'company/create',
        name: 'company-create',
        component: () => import('@/views/admin/company/Create'),
        meta: {
          title: 'Добавление партнера - Международный Клуб Привилегий РСМ'
        },
      },
      {
        path: 'company/:id/discount/create',
        name: 'company-discount-create',
        component: () => import('@/views/admin/company/discount/Create.vue'),
        meta: {
          title: 'Добавление скидки - Международный Клуб Привилегий РСМ'
        },
      },
      {
        path: 'company/:id/discount/:discount',
        name: 'company-discount-detail',
        component: () => import('@/views/admin/company/discount/Detail.vue'),
        meta: {
          title: 'Редактирование скидки - Международный Клуб Привилегий РСМ'
        },
      },
      {
        path: 'company/:id/address/create',
        name: 'company-address-create',
        component: () => import('@/views/admin/company/address/Create.vue'),
        meta: {
          title: 'Добавление адреса - Международный Клуб Привилегий РСМ'
        },
      },
      {
        path: 'company/:id/address/:address',
        name: 'company-address-detail',
        component: () => import('@/views/admin/company/address/Detail.vue'),
        meta: {
          title: 'Редактирование адреса - Международный Клуб Привилегий РСМ'
        },
      },
      {
        path: 'company',
        component: () => import('@/views/admin/company/Index'),
        meta: {
          title: 'МКП - Партнер'
        },
        children: [
          {
            path: '',
            name: 'company',
            redirect: {name: 'admin'}
          },
          {
            path: ':id',
            name: 'company-profile',
            component: () => import('@/views/admin/company/Profile'),
            meta: {
              title: 'Профиль партнера - Международный Клуб Привилегий РСМ'
            },
            props: {pageTitle: 'Профиль'}
          },
          {
            path: ':id/discount',
            name: 'company-discount',
            component: () => import('@/views/admin/company/discount/List'),
            meta: {
              title: 'Скидки - Международный Клуб Привилегий РСМ'
            },
            props: { pageTitle: 'Скидка' }
          },
          {
            path: ':id/address',
            name: 'company-address',
            component: () => import('@/views/admin/company/address/List'),
            meta: {
              title: 'Адреса - Международный Клуб Привилегий РСМ'
            },
            props: { pageTitle: 'Адреса' }
          },
          {
            path: ':id/person',
            name: 'company-person',
            component: () => import('@/views/admin/company/Person'),
            meta: {
              title: 'Контактное лицо - Международный Клуб Привилегий РСМ'
            },
            props: {pageTitle: 'Контактное лицо'}
          },
          {
            path: ':id/discount-log',
            name: 'company-discount-log',
            component: () => import('@/views/admin/company/report/DiscountLog'),
            meta: {
              title: 'Проведенные скидки - Международный Клуб Привилегий РСМ'
            },
          },
          {
            path: ':id/verify-log',
            name: 'company-verify-log',
            component: () => import('@/views/admin/company/report/VerifyLog'),
            meta: {
              title: 'Проверенные карты - Международный Клуб Привилегий РСМ'
            },
          },
          {
            path: ':id/docs',
            name: 'company-docs',
            component: () => import('@/views/admin/company/Docs'),
            meta: {
              title: 'Документы - Международный Клуб Привилегий РСМ'
            },
          },
        ]
      },
      {
        path: 'faq/create',
        name: 'faq-create',
        component: () => import('@/views/admin/faq/Create.vue'),
        meta: {
          title: 'Добавление FAQ - Международный Клуб Привилегий РСМ'
        },
      },
      {
        path: 'faq',
        name: 'faq-list',
        component: () => import('@/views/admin/faq/List.vue'),
        meta: {
          title: 'FAQ Партнеров - Международный Клуб Привилегий РСМ'
        },
      },
      {
        path: 'faq/:id',
        name: 'faq-detail',
        component: () => import('@/views/admin/faq/Detail.vue'),
        meta: {
          title: 'Редактирование FAQ - Международный Клуб Привилегий РСМ'
        },
      },
      {
        path: 'news/create',
        name: 'news-create',
        component: () => import('@/views/admin/news/Create.vue'),
        meta: {
          title: 'Добавление Новости - Международный Клуб Привилегий РСМ'
        },
      },
      {
        path: 'news',
        name: 'news-list',
        component: () => import('@/views/admin/news/List.vue'),
        meta: {
          title: 'Новости - Международный Клуб Привилегий РСМ'
        },
      },
      {
        path: 'news/:id',
        name: 'news-detail',
        component: () => import('@/views/admin/news/Detail.vue'),
        meta: {
          title: 'Редактирование Новости - Международный Клуб Привилегий РСМ'
        },
      },
      {
        path: 'contacts',
        name: 'contacts',
        component: () => import('@/views/admin/contacts/Index.vue'),
        redirect: {name: 'contacts-main'},
        children: [
          {
            path: 'main',
            name: 'contacts-main',
            component: () => import('@/views/admin/contacts/main/List.vue'),
            meta: {
              title: 'Контакты - Международный Клуб Привилегий РСМ'
            },
          },
          {
            path: 'additional',
            name: 'contacts-additional',
            component: () => import('@/views/admin/contacts/additional/List.vue'),
            meta: {
              title: 'Контакты - Международный Клуб Привилегий РСМ'
            },
          },
          {
            path: 'department',
            name: 'contacts-department',
            component: () => import('@/views/admin/contacts/department/List.vue'),
            meta: {
              title: 'Контакты - Международный Клуб Привилегий РСМ'
            },
          },
        ]
      },
      {
        path: 'contacts/main/create',
        name: 'contacts-main-create',
        component: () => import('@/views/admin/contacts/main/Create.vue'),
        meta: {
          title: 'Добавление контакта - Международный Клуб Привилегий РСМ'
        },
      },
      {
        path: 'contacts/main/:id',
        name: 'contacts-main-detail',
        component: () => import('@/views/admin/contacts/main/Detail.vue'),
        meta: {
          title: 'Редактирование контакта - Международный Клуб Привилегий РСМ'
        },
      },
      {
        path: 'contacts/additional/create',
        name: 'contacts-additional-create',
        component: () => import('@/views/admin/contacts/additional/Create.vue'),
        meta: {
          title: 'Добавление контакта - Международный Клуб Привилегий РСМ'
        },
      },
      {
        path: 'contacts/additional/:id',
        name: 'contacts-additional-detail',
        component: () => import('@/views/admin/contacts/additional/Detail.vue'),
        meta: {
          title: 'Редактирование контакта - Международный Клуб Привилегий РСМ'
        },
      },
      {
        path: 'export',
        name: 'export',
        component: () => import('@/views/admin/Export'),
        meta: {
          title: 'Экспорт - Международный Клуб Привилегий РСМ'
        },
      },
      {
        path: 'login',
        name: 'admin-login',
        component: () => import('@/views/admin/Login.vue'),
        meta: {
          layout: 'welcome',
          title: 'Вход в кабинет администратора - Международный Клуб Привилегий РСМ'
        },
      },
    ],
  },
  {
    path: '/',
    component: Partner,
    redirect: 'partner',
    children: [
      {
        path: 'partner',
        name: 'partner',
        component: () => import('@/views/partner/Profile.vue'),
        meta: {
          title: 'Профиль партнера - Международный Клуб Привилегий РСМ'
        }
      },
      {
        path: 'partner/login',
        name: 'partner-login',
        component: () => import('@/views/partner/auth/Login.vue'),
        meta: {
          layout: 'welcome',
          title: 'Вход в кабинет партнера - Международный Клуб Привилегий РСМ'
        },
      },
      {
        path: 'partner/password-recovery',
        name: 'password-recovery',
        component: () => import('@/views/public/PasswordRecovery.vue'),
        meta: {
          layout: 'welcome',
          title: 'Восстановление пароля - Международный Клуб Привилегий РСМ'
        }
      },
      {
        path: 'partner/password-confirm',
        name: 'password-confirm',
        component: () => import('@/views/public/PasswordConfirm.vue'),
        meta: {
          layout: 'welcome',
          title: 'Подтверждение пароля - Международный Клуб Привилегий РСМ'
        }
      },
      {
        path: 'partner/registration',
        name: 'registration',
        component: () => import('@/views/partner/auth/Registration.vue'),
        meta: {
          layout: 'public',
          title: 'Регистрация участника - Международный Клуб Привилегий РСМ'
        }
      },
      {
        path: 'partner/person',
        name: 'partner-person',
        component: () => import('@/views/partner/Person.vue'),
        meta: {
          title: 'Контактное лицо - Международный Клуб Привилегий РСМ'
        },
      },
      {
        path: 'partner/verify',
        name: 'partner-verify',
        component: () => import('@/views/partner/Verify.vue'),
        meta: {
          title: 'Проверка карты МКП - Международный Клуб Привилегий РСМ'
        },
      },
      {
        path: 'partner/discounts',
        name: 'partner-discounts',
        component: () => import('@/views/partner/discount/List.vue'),
        meta: {
          title: 'Скидки - Международный Клуб Привилегий РСМ'
        }
      },
      {
        path: 'partner/discounts/create',
        name: 'partner-discounts-create',
        component: () => import('@/views/partner/discount/Create.vue'),
        meta: {
          title: 'Добавление скидки - Международный Клуб Привилегий РСМ'
        }
      },
      {
        path: 'partner/discounts/:id',
        name: 'partner-discounts-detail',
        component: () => import('@/views/partner/discount/Detail.vue'),
        meta: {
          title: 'Редактирование скидки - Международный Клуб Привилегий РСМ'
        }
      },
      {
        path: 'partner/address',
        name: 'partner-address',
        component: () => import('@/views/partner/address/List.vue'),
        meta: {
          title: 'Адреса - Международный Клуб Привилегий РСМ'
        },
      },
      {
        path: 'partner/address/create',
        name: 'partner-address-create',
        component: () => import('@/views/partner/address/Create.vue'),
        meta: {
          title: 'Добавление адреса - Международный Клуб Привилегий РСМ'
        },
      },
      {
        path: 'partner/address/:id',
        name: 'partner-address-detail',
        component: () => import('@/views/partner/address/Detail.vue'),
        meta: {
          title: 'Редактирование адреса - Международный Клуб Привилегий РСМ'
        }
      },
      {
        path: 'partner/faq',
        name: 'partner-faq',
        component: () => import('@/views/partner/FAQList'),
        meta: {
          title: 'FAQ - Международный Клуб Привилегий РСМ'
        },
      },
      {
        path: 'partner/news',
        name: 'partner-news',
        component: () => import('@/views/partner/news/List'),
        meta: {
          title: 'Новости - Международный Клуб Привилегий РСМ'
        },
      },
      {
        path: 'partner/news/:slug',
        name: 'partner-news-detail',
        component: () => import('@/views/partner/news/Detail'),
        meta: {
          title: 'Новости - Международный Клуб Привилегий РСМ'
        },
      },
      {
        path: 'partner/contacts',
        name: 'partner-contacts',
        component: () => import('@/views/partner/Contacts'),
        meta: {
          title: 'Контакты - Международный Клуб Привилегий РСМ'
        },
      },
      {
        path: 'partner/report',
        name: 'partner-report',
        component: () => import('@/views/partner/report/Index'),
        redirect: {name: 'report-discount'},
        children: [
          {
            path: 'discount',
            name: 'report-discount',
            component: () => import('@/views/partner/report/DiscountLog'),
            meta: {
              title: 'Проведенные скидки - Международный Клуб Привилегий РСМ'
            },
          },
          {
            path: 'verify',
            name: 'report-verify',
            component: () => import('@/views/partner/report/VerifyLog'),
            meta: {
              title: 'Проверенные карты - Международный Клуб Привилегий РСМ'
            },
          },
        ]
      },
      {
        path: 'partner/docs',
        name: 'partner-docs',
        component: () => import('@/views/partner/Docs'),
        meta: {
          title: 'Документы - Международный Клуб Привилегий РСМ'
        },
      },
    ]
  },
  {
    path: '/card',
    component: Card,
    children: [
      {
        path: '',
        name: 'card',
        component: () => import('@/views/public/card/Form'),
        meta: {
          layout: 'public',
          title: 'Оформление заявки - Международный Клуб Привилегий РСМ'
        },
      },
      {
        path: 'success',
        name: 'card-success',
        component: () => import('@/views/public/card/Success'),
        meta: {
          layout: 'public',
          title: 'Заявка успешно отправлена - Международный Клуб Привилегий РСМ'
        }
      },
      {
        path: 'success-payment',
        name: 'success-payment',
        component: () => import('@/views/public/card/SuccessPayment'),
        meta: {
          layout: 'public',
          title: 'Успешная оплата карты - Международный Клуб Привилегий РСМ'
        }
      },
      {
        path: 'error-payment',
        name: 'error-payment',
        component: () => import('@/views/public/card/ErrorPayment'),
        meta: {
          layout: 'public',
          title: 'Ошибка оплаты карты - Международный Клуб Привилегий РСМ'
        },
      },
    ]
  },
  {
    path: '/card-gpb',
    component: Card,
    children: [
      {
        path: '',
        name: 'card-gpb',
        component: () => import('@/views/public/card-gpb/Form'),
        meta: {
          layout: 'public',
          title: 'Оформление заявки - Международный Клуб Привилегий РСМ'
        },
      },
      {
        path: 'success',
        name: 'card-success-gpb',
        component: () => import('@/views/public/card-gpb/Success'),
        meta: {
          layout: 'public',
          title: 'Заявка успешно отправлена - Международный Клуб Привилегий РСМ'
        }
      },
    ]
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/public/Privacy'),
    meta: {
      layout: 'public',
      title: 'Политика конфиденциальности - Международный Клуб Привилегий РСМ'
    }
  },
  {
    path: '/dos',
    name: 'dos',
    component: () => import('@/views/public/Dos'),
    meta: {
      layout: 'public',
      title: 'Условия возврата, доставки и процесса описания передачи данны - Международный Клуб Привилегий РСМ'
    }
  },
  {
    path: '/discounts',
    component: Discounts,
    children: [
      {
        path: '',
        name: 'partners-list',
        component: () => import('@/views/public/discounts/PartnerList'),
        meta: {
          layout: 'empty',
          title: 'Скидки в России - Международный Клуб Привилегий РСМ'
        }
      },
      {
        path: ':id/discount/:discount',
        name: 'partners-discount',
        component: () => import('@/views/public/discounts/DiscountDetail'),
        meta: {
          layout: 'empty',
          title: 'Скидки в России - Международный Клуб Привилегий РСМ'
        }
      },
      {
        path: ':id',
        name: 'partners-profile',
        component: () => import('@/views/public/discounts/PartnerProfile'),
        meta: {
          layout: 'empty',
          title: 'Скидки в России - Международный Клуб Привилегий РСМ'
        }
      },
    ],
  },
  {
    path: '/verify',
    name: 'nordwings-verify',
    component: () => import('@/views/public/Verify'),
    meta: {
      layout: 'verify',
      title: 'Nordwind - Международный Клуб Привилегий РСМ'
    }
  },
  {
    path: '/oferta',
    name: 'offer',
    component: () => import('@/views/public/Offer'),
    meta: {
      layout: 'public',
      title: 'Оферта - Международный Клуб Привилегий РСМ'
    }
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('@/views/public/Terms'),
    meta: {
      layout: 'public',
      title: 'Правила использования сайта - Международный Клуб Привилегий РСМ'
    }
  },
  {
    path: '/404',
    name: 'error-404',
    component: () => import('@/views/public/Error404'),
    meta: {
      layout: 'public',
      title: 'Страница не найдена - Международный Клуб Привилегий РСМ'
    }
  },
  {
    path: '*',
    redirect: '404'
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.afterEach(() => {
  const appLoading = document.getElementById('loading');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  const loggedIn = localStorage.getItem('accessToken');
  const user = localStorage.getItem('user');
  const regionalAdmin = localStorage.getItem('adminType') === 'regional';
  const path = to.fullPath.split('/');

  if (to.fullPath.includes('partner') && !loggedIn && to.path !== '/partner/login' && to.path !== '/partner/registration'
    && to.path !== '/partner/password-recovery' && to.path !== '/partner/password-confirm') {
    next('/partner/login');
  } else if (to.fullPath.includes('admin') && !loggedIn && to.path !== '/admin/login') {
    next('/admin/login');
  } else {
    next();
  }

  if (to.fullPath.includes('admin') && loggedIn && user === 'partner') {
    next({name: 'partner'});
  } else if (to.fullPath.includes('partner') && loggedIn && user === 'admin') {
    next({name: 'admin'});
  } else if (to.fullPath.includes('admin') && loggedIn && regionalAdmin && path.length >= 3
    && !to.fullPath.includes('company') && !to.fullPath.includes('person') && !to.fullPath.includes('statistics')) {
    next({name: 'admin'});
  } else {
    next();
  }

  if (loggedIn && user === 'admin' && to.fullPath.includes('login')) {
    next({name: 'admin'});
  } else if (loggedIn && user === 'partner' && to.fullPath.includes('login')) {
    next({name: 'partner'});
  }

  if (
    to.name === 'password-recovery' ||
    to.name === 'card-success' ||
    to.name === 'success-payment' ||
    to.name === 'error-payment' ||
    to.name === 'privacy' ||
    to.name === 'dos' ||
    to.name === 'error-404' ||
    to.name === 'registration' ||
    loggedIn
  ) {
    if (loggedIn && to.name === 'registration') {
      next({ name: 'partner' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
