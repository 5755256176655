import axios from '@/http/axios/axiosAdmin';

export default {
  namespaced: true,
  actions: {
    GET_LIST({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`card/?page=${data.page}&page_size=${data.limit}`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_QUERY({ commit }, query) {
      return new Promise((resolve, reject) => {
        axios
          .get(`card/?query=${query}`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
  }
};