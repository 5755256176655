import axios from '@/http/axios/axiosAdmin';

export default {
  namespaced: true,
  actions: {
    GET_LIST({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`partner/${id}/discount/`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_DATA({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`partner/${payload.id}/discount/${payload.discount}/`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    POST_DATA({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`partner/${payload.id}/discount/`, payload.data)
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
    PATCH_DATA({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`partner/${payload.id}/discount/${payload.discount}/`, payload.data)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    DELETE_DATA({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/partner/${payload.id}/discount/${payload.discount}/`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
  }
};