import axios from '@/http/axios/axiosAdmin';

export default {
  namespaced: true,
  state: () => ({
    managers: [],
    page: 1,
    count: 1,
    limit: 20,
    all: 0,
    length: 0,
  }),
  mutations: {
    getList(state, payload) {
      state.managers = payload;
    },
    changePage(state, payload) {
      state.page = payload;
    },
    changeCount(state, payload) {
      state.count = payload;
    },
    changeLimit(state, payload) {
      state.limit = payload;
    },
    setAll(state, payload) {
      state.all = payload;
    },
    setLength(state, payload) {
      state.length = payload;
    },
  },
  actions: {
    GET_LIST({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`managers/?page_size=${data.limit}&page=${data.page}`)
          .then(response => {
            commit('getList', response.data.results);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_DATA({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`managers/${id}/`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    POST_DATA({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('managers/', data)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    PATCH_DATA({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/managers/${payload.id}/`, payload.data)
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
    DELETE_DATA({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`managers/${id}/`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
  }
};