import axiosDef from 'axios';
import axios from '@/http/axios/axiosPartner';
import jwt from '@/http/requests/partner/auth';
import jwt_decode from 'jwt-decode';

export default {
  namespaced: true,
  state: {
    isUserLoggedIn: () => {
      return localStorage.getItem('accessToken');
    }
  },
  mutations: {
    SET_BEARER (state, accessToken) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }
  },
  actions: {
    loginJWT ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        jwt.login(payload.userDetails.email, payload.userDetails.password)
          .then(response => {
            if (response.data.access) {
              const decode = jwt_decode(response.data.access);
              if (decode.is_partner) {
                localStorage.setItem('accessToken', response.data.access);
                localStorage.setItem('refreshToken', response.data.refresh);
                localStorage.setItem('user', 'partner');
                commit('SET_BEARER', response.data.access);
              } else {
                reject({message: 'У Вас недостаточно прав'});
              }
              resolve(response);
            } else {
              reject({message: 'Неверное имя или пароль'});
            }
          })
          .catch(() => {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');

            reject({message: 'Неверное имя или пароль'});
          });
      });
    },
    logoutJWT() {
      jwt.logout();
    },
    fetchAccessToken () {
      return new Promise((resolve) => {
        jwt.refreshToken().then(response => { resolve(response); });
      });
    },
    POST_REGISTRATION({ commit }, data) {
      return axios.post('reg/', data)
    },
    POST_RECOVERY(store, data) {
      return new Promise((resolve, reject) => {
        axiosDef
          .post(`${process.env.VUE_APP_PARTNER_URL}password_reset/`, data)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    POST_CONFIRM({ commit }, data) {
      return new Promise((resolve, reject) => {
        axiosDef
          .post(`${process.env.VUE_APP_PARTNER_URL}password_reset/confirm/`, data)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
  }
};
