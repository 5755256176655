import axios from '@/http/axios/axiosAdmin';

export default {
  namespaced: true,
  actions: {
    // GET_LIST({ commit }, data) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get('card-free/')
    //       .then(response => resolve(response))
    //       .catch(error => {
    //         reject(error);
    //       });
    //   });
    // },
    GET_IMPORT({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`card-free/logs/?page=${data.page}&page_size=${data.limit}`)
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_EXPORT({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/card-free/request/list/?page=${data.page}&page_size=${data.limit}`)
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
    POST_EXPORT({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/card-free/request/', payload)
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
  }
};