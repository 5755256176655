<template>
  <router-view />
</template>

<script>
import jwt from '@/http/requests/admin/auth';

export default {
  name: 'Admin',
  async created () {
    jwt.init();
  }
};
</script>