import axios from '@/http/axios/axiosPartner';

export default {
  namespaced: true,
  actions: {
    GET_LIST() {
      return new Promise((resolve, reject) => {
        axios
          .get('discount/')
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_DATA({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`discount/${id}`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    POST_DATA({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('discount/', data)
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
    PATCH_DATA({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`discount/${payload.id}/`, payload.data)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    DELETE_DATA({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`discount/${id}/`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
  }
};