import axios from '@/http/axios/axiosPublic';

export default {
  namespaced: true,
  actions: {
    GET_TARIFFS() {
      return new Promise((resolve, reject) => {
        axios
          .get('card/tariff/')
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_REGIONS({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`region/?region=${data.search}`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_ADDRESS({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`address/?q=${data.search}`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    POST_CROPPER({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('card/files', data)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    POST_FORM_CARD({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('card/', data)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    POST_FORM_CARD_GPB({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('gazprom/request/', data)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_PARTNERS(state, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`partners/?page_size=${data.limit}&page=${data.page}&category=${data.categories}&services_types=${data.types}&query=${data.query}&regions=${data.regions}`)
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_PARTNER(state, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`partners/${id}`)
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_DISCOUNTS(state, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`partners/${id}/discount/`)
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_DISCOUNT(state, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`partners/${data.id}/discount/${data.discount}/`)
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_ADDRESSES(state, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`partners/${id}/address/`)
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_PHOTOS(state, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`partners/${id}/photos/`)
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_MAP(state, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`partners/map/?services_types=${data.types}&category=${data.categories}&query=${data.query}&regions=${data.regions}`)
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_CATEGORIES() {
      return new Promise((resolve, reject) => {
        axios
          .get('partners/category/')
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
    POST_VERIFY({ commit }, data) {
      return new Promise((resolve, reject) => {
        //.post(`partners/${data.slug}/hold-discount/`, data.card_number)
        const bodyFormData = new FormData();
        bodyFormData.append('card_number', data.card_number);
        axios({
          method: 'post',
          url: `partners/${data.slug}/hold-discount/`,
          data: bodyFormData,
          headers: {'Content-Type': 'multipart/form-data'}
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_EVENTS() {
      return new Promise((resolve, reject) => {
        axios
          .get('gazprom/events/')
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
  }
};

