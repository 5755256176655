<template>
  <div class="verify">
    <div class="verify__logo">
      <img src="@/assets/img/NW-logo.svg" alt="">
    </div>
    <div class="verify__decor">
      <div class="verify__plane">
        <img src="@/assets/img/girl.png" alt="Plane image">
      </div>
      <div class="verify__text">переключись по полной со скидкой 7%</div>
    </div>
    <div class="verify__container">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
};
</script>

<style lang="sass">
@import "@/assets/common/index.scss"

.verify
  position: relative

  &:before
    content: ""
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 457px
    background-image: url("../assets/img/sky-bg-2.jpg")
    background-repeat: no-repeat
    background-position: center
    background-size: cover
    z-index: -1

    +max-w($mobile_md)
      height: 560px

    +max-w($mobile_sm)
      height: 524px

.verify__plane
  width: 320px
  margin-right: 5px
  line-height: 0

  +max-w($mobile_md)
    margin: 0

.verify__logo
  position: absolute
  top: 70px
  left: 50%
  transform: translateX(-50%)
  line-height: 0

.verify__decor
  display: flex
  justify-content: space-between
  align-items: center
  max-width: 775px
  margin: 0 auto
  padding: 173px 10px 0

  +max-w($mobile_md)
    flex-direction: column
    padding: 160px 10px 0

.verify__text
  max-width: 340px
  color: #fff
  font-size: 40px
  font-weight: 600
  text-transform: uppercase

  +max-w($mobile_lg)
    max-width: 285px
    font-size: 32px

  +max-w($mobile_md)
    order: -1
    max-width: 100%
    margin-bottom: 20px
    text-align: center

  +max-w($mobile_sm)
    font-size: 20px

.verify__container
  max-width: 840px
  margin: 0 auto

</style>
