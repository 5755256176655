import jwt from '@/http/requests/admin/auth';
import axios from '@/http/axios/axiosAdmin';
import jwt_decode from 'jwt-decode';

export default {
  namespaced: true,
  state: {
    isUserLoggedIn: () => {
      return localStorage.getItem('accessToken');
    }
  },
  mutations: {
    SET_BEARER (state, accessToken) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }
  },
  actions: {
    loginJWT ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        jwt.login(payload.userDetails.email, payload.userDetails.password)
          .then(response => {
            if (response.data.access) {
              const decode = jwt_decode(response.data.access);
              if (decode.is_admin) {
                localStorage.setItem('accessToken', response.data.access);
                localStorage.setItem('refreshToken', response.data.refresh);
                commit('SET_BEARER', response.data.access);
                localStorage.setItem('user', 'admin');
              } else {
                reject({message: 'У Вас недостаточно прав'});
              }
              resolve(response);
            } else {
              reject({message: 'Неверное имя или пароль'});
            }
          })
          .catch(() => {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');

            reject({message: 'Неверное имя или пароль'});
          });
      });
    },
    logoutJWT() {
      jwt.logout();
    },
    fetchAccessToken () {
      return new Promise((resolve) => {
        jwt.refreshToken().then(response => { resolve(response); });
      });
    }
  }
};
