import axios from '@/http/axios/axiosAdmin';

export default {
  namespaced: true,
  actions: {
    GET_LIST({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`news/?page=${payload.page}&page_size=${payload.limit}`)
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_DATA({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`news/${id}/`)
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
    POST_DATA({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('news/', payload)
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
    PATCH_DATA({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`news/${payload.id}/`, payload)
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
    DELETE_DATA({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`news/${id}/`)
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
  }
};