import axios from '@/http/axios/axiosAdmin';

export default {
  namespaced: true,
  actions: {
    GET_EXPORT_GPB() {
      return new Promise((resolve, reject) => {
        axios
          .get('export/card-gpb/', { responseType: 'blob' })
          .then(response => { resolve(response); })
          .catch(error => { reject(error); });
      });
    },
    GET_EXPORT_PARTNER() {
      return new Promise((resolve, reject) => {
        axios
          .get('export/partner/', { responseType: 'blob' })
          .then(response => { resolve(response); })
          .catch(error => { reject(error); });
      });
    },
    GET_EXPORT_STAT() {
      return new Promise((resolve, reject) => {
        axios
          .get('export/uni-stat-gpb/', { responseType: 'blob' })
          .then(response => { resolve(response); })
          .catch(error => { reject(error); });
      });
    },
    GET_EXPORT_EYCA() {
      return new Promise((resolve, reject) => {
        axios
          .get('export/card/', { responseType: 'blob' })
          .then(response => { resolve(response); })
          .catch(error => { reject(error); });
      });
    },
  }
};