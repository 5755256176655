import axios from '@/http/axios/axiosAdmin';

export default {
  namespaced: true,
  actions: {
    GET_MAIN_LIST() {
      return new Promise((resolve, reject) => {
        axios
          .get('contacts/')
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_MAIN_DATA({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`contacts/${id}/`)
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
    POST_MAIN_DATA({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('contacts/', payload)
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
    PATCH_MAIN_DATA({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`contacts/${payload.id}/`, payload)
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
    DELETE_MAIN_DATA({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`contacts/${id}/`)
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_ADD_LIST() {
      return new Promise((resolve, reject) => {
        axios
          .get('contacts-additional/')
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_ADD_DATA({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`contacts-additional/${id}/`)
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
    POST_ADD_DATA({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('contacts-additional/', payload)
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
    PATCH_ADD_DATA({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`contacts-additional/${payload.id}/`, payload)
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
    DELETE_ADD_DATA({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`contacts-additional/${id}/`)
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_DEP_LIST() {
      return new Promise((resolve, reject) => {
        axios
          .get('contacts-department/')
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_DEP_QUERY({ commit }, query) {
      return new Promise((resolve, reject) => {
        axios
          .get(`contacts-department/?query=${query}`)
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_DEP_DATA({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`contacts-department/${id}/`)
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
    POST_DEP_DATA({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('contacts-department/', payload)
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
    PATCH_DEP_DATA({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`contacts-department/${payload.id}/`, payload.form)
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
    DELETE_DEP_DATA({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`contacts-department/${id}/`)
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
  }
};