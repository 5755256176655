import axiosPartner from '@/http/axios/axiosPartner';
import router from '@/router';

export default {
  init() {
    axiosPartner.interceptors.response.use(
      function (response) {
        return response
      },
      function (err) {
        console.log(err.response)
        if ((err.response && err.response.status === 401) || err.status === 401) {
          return new Promise(function () {
            router.go();
            localStorage.removeItem('accessToken');
            axiosPartner.post('/token/jwt/refresh/', { refresh: localStorage.getItem('refreshToken') })
              .then(response => {
                localStorage.setItem('accessToken', response.data.access);
              })
              .catch(err => {
                localStorage.clear();
                router.push({name: 'partner-login'}).catch(() => {});
              });
          });
        }
        else return Promise.reject(err.response || err)
    });
  },
  login(email, pwd) {
    return axiosPartner.post('/token/jwt/create/', {
      email,
      password: pwd
    });
  },
  logout() {
    localStorage.clear();
    delete axiosPartner.defaults.headers.common['Authorization'];
  },
  refreshToken() {
    return axiosPartner.post('/token/jwt/refresh/', { refresh: localStorage.getItem('refreshToken') });
  },
};
