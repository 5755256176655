import axios from '@/http/axios/axiosPartner';

export default {
  namespaced: true,
  actions: {
    GET_LIST() {
      return new Promise((resolve, reject) => {
        axios
          .get('news/')
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_DATA({ commit }, slug) {
      return new Promise((resolve, reject) => {
        axios
          .get(`news/${slug}/`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
  }
};