import axios from '@/http/axios/axiosPartner';

export default {
  namespaced: true,
  actions: {
    GET_DISCOUNT_LOG() {
      return new Promise((resolve, reject) => {
        axios
          .get('discount-log/')
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_VERIFY_LOG() {
      return new Promise((resolve, reject) => {
        axios
          .get('verify-log/')
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
  }
};