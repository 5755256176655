import axios from '@/http/axios/axiosPartner';

export default {
  namespaced: true,
  actions: {
    GET_DATA() {
      return new Promise((resolve, reject) => {
        axios
          .get('me/profile/')
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    PATCH_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch('/me/profile/update', payload)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_PERSON() {
      return new Promise((resolve, reject) => {
        axios
          .get('me/auth/')
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
    PATCH_PERSON({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .patch('me/auth/update', data)
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_TOKEN() {
      return new Promise((resolve, reject) => {
        axios
          .get('api-token/')
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
    POST_TOKEN() {
      return new Promise((resolve, reject) => {
        axios
          .post('api-token/')
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
    POST_CHECK_PROFILE() {
      return new Promise((resolve, reject) => {
        axios
          .post('me/profile/check')
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
  }
};