import axios from 'axios';

const baseURL = process.env.VUE_APP_PARTNER_URL;
const apiClient = axios.create({
  baseURL
});

apiClient.interceptors.request.use(function (config) {
  const token = localStorage.getItem('accessToken');
  config.headers['Authorization'] = `Bearer ${token}`;
  return config;
});

export default apiClient;
