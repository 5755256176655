import Vue from 'vue';
import vSelect from 'vue-select';
import VueClip from 'vue-clip';
import VModal from 'vue-js-modal/dist/index';
import Notifications from 'vue-notification';
import Vue2Storage from 'vue2-storage';
import DefaultLayout from './layouts/Default';
import WelcomeLayout from './layouts/Welcome';
import PublicLayout from './layouts/Public';
import EmptyLayout from './layouts/Empty';
import VerifyLayout from './layouts/Verify';
import VueTables from 'vue-tables-2';
import Paginate from 'vuejs-paginate';
import VTooltip from 'v-tooltip';
import VueLazyLoad from 'vue-lazyload';

Vue.component('default', DefaultLayout);
Vue.component('welcome', WelcomeLayout);
Vue.component('public', PublicLayout);
Vue.component('empty', EmptyLayout);
Vue.component('verify', VerifyLayout);
Vue.component('v-select', vSelect);
Vue.component('paginate', Paginate);
Vue.directive('tooltip', VTooltip.VTooltip);
Vue.directive('close-popover', VTooltip.VClosePopover);
Vue.component('v-popover', VTooltip.VPopover);

Vue.use(VueClip);
Vue.use(VModal);
Vue.use(Notifications);
Vue.use(Vue2Storage);
Vue.use(VueTables.ClientTable);
Vue.use(VTooltip);
Vue.use(VueLazyLoad);

