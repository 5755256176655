import axiosAdmin from '@/http/axios/axiosAdmin';
import router from '@/router';

export default {
  init() {
    axiosAdmin.interceptors.response.use(
      function (response) {
        return response
      },
      function (err) {
        if ((err.response && err.response.status === 401) || err.status === 401) {
          return new Promise(function () {
            router.go();
            localStorage.removeItem('accessToken');
            axiosAdmin.post('/token/jwt/refresh/', {refresh: localStorage.getItem('refreshToken')})
              .then(response => {
                localStorage.setItem('accessToken', response.data.access);
              })
              .catch(err => {
                localStorage.clear();
                router.push({name: 'admin-login'}).catch(() => {});
              });
          });
        }
        else return Promise.reject(err.response || err)
    });
  },
  login (email, pwd) {
    return axiosAdmin.post('/token/jwt/create/', {
      email,
      password: pwd
    });
  },
  logout() {
    localStorage.clear();
    delete axiosAdmin.defaults.headers.common['Authorization'];
  },
  refreshToken (token) {
    return axiosAdmin.post('/token/jwt/refresh/', { refresh: token });
  }
};
