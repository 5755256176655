<template>
  <router-view />
</template>

<script>
export default {
  name: 'Partners',
};
</script>

<!--<style lang="sass">-->
<!--.fade-enter-active, .fade-leave-active-->
<!--  transition: opacity 1s-->

<!--.fade-enter, .fade-leave-to-->
<!--  opacity: 0-->
<!--</style>-->