<template>
  <header class="header">
    <div class="header__part">
      <button
        @click="sidebarMobileOpen"
        class="header__burger"
      >
        <span></span>
      </button>
    </div>
    <div class="header__part">
      <div class="header__user">
        <router-link
          :to="profileLink"
          class="header__info"
        >
          <div class="header__photo">
            <img src="@/assets/img/user-slug.svg" alt="User photo">
          </div>
        </router-link>
        <button
          @click="logout"
          class="user__logout"
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 8.25V6.375C15 5.87772 14.8025 5.40081 14.4508 5.04917C14.0992 4.69754 13.6223 4.5 13.125 4.5H4.125C3.62772 4.5 3.15081 4.69754 2.79917 5.04917C2.44754 5.40081 2.25 5.87772 2.25 6.375V17.625C2.25 18.1223 2.44754 18.5992 2.79917 18.9508C3.15081 19.3025 3.62772 19.5 4.125 19.5H13.125C13.6223 19.5 14.0992 19.3025 14.4508 18.9508C14.8025 18.5992 15 18.1223 15 17.625V15.75" stroke="#323F4B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M18 8.25L21.75 12L18 15.75" stroke="#323F4B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.95312 12H21.75" stroke="#323F4B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      user: localStorage.getItem('user'),
    };
  },
  computed: {
    profileLink() {
      return this.user === 'admin' ? { name: 'admin-person' } : { name: 'partner-person' };
    }
  },
  methods: {
    sidebarMobileOpen() {
      this.$store.commit('sidebarMobileToggle');
    },
    logout() {
      this.user = localStorage.getItem('user') ?? '';
      if (this.user === 'partner') {
        this.$store.dispatch('partner/auth/logoutJWT')
          .then(() => {
            this.$router.push({name: `${this.user}-login`});
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: 'Ошибка',
              text: 'Ошибка авторизации'
            });
          });
      } else if (this.user === 'admin') {
        this.$store.dispatch('admin/auth/logoutJWT')
          .then(() => {
            this.$router.push({name: `${this.user}-login`});
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: 'Ошибка',
              text: 'Ошибка авторизации'
            });
          });
      }
    },
  }
};
</script>

<style lang="sass">
@import "@/assets/common/index.scss"

.header
  display: none
  position: relative
  justify-content: space-between
  align-items: center
  padding: 20px 20px 0
  background-color: #fff

  +max-w($laptop_md)
    display: flex

.header__part
  position: relative
  display: flex
  align-items: center
  flex-shrink: 0

  &:first-child
    flex-grow: 1
    margin-right: 40px

    +max-w($tablet)
      margin-right: 20px
    //
    //+max-w($mobile_xl)
    //  order: 1
    //  margin-right: 0

.header__block
  display: flex
  align-items: center
  flex-shrink: 0

.header__count
  position: absolute
  top: 0
  right: -2px
  width: 15px
  height: 15px
  border-radius: 50%
  color: #fff
  background-color: #FF9416
  font-size: 10px
  text-align: center

.header__burger
  position: relative
  display: flex
  align-items: center
  width: 25px
  height: 25px
  padding: 2px
  cursor: pointer

  span,
  span:before,
  span:after
    position: absolute
    display: block
    width: 20px
    height: 2px
    border-radius: 1px
    background-color: $color-theme

  span
    &:before,
    &:after
      content: ''
      position: absolute

    &:before
      top: -6px

    &:after
      bottom: -6px

  +min-w($laptop_md)
    display: none

.header__user
  display: flex
  align-items: center

.header__info
  margin-right: 20px

.header__photo
  line-height: 0
</style>
