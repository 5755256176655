import axios from '@/http/axios/axiosAdmin';
import auth from './auth';
import request from './request';
import manager from './manager';
import company from './company';
import cards from './cards';
import cardsGpb from './cards-gpb';
import discounts from './discounts';
import cardsFree from './cards-free';
import universities from './universities';
import faq from './faq';
import news from './news';
import contacts from './contacts';
import exportExcel from './export';

export default {
  namespaced: true,
  state: () => ({
    tableLimit: 20,
  }),
  mutations: {
    changeTableLimit(state, payload) {
      state.tableLimit = payload;
    },
  },
  actions: {
    POST_CROPPER({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('files', data)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_INN_OPTIONS({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`find-org/?q=${data.search}`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_STATISTICS() {
      return new Promise((resolve, reject) => {
        axios
          .get('statistics/')
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_REGION_STATISTICS() {
      return new Promise((resolve, reject) => {
        axios
          .get('statistics/region')
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_REGIONS() {
      return new Promise((resolve, reject) => {
        axios
          .get('region/')
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_REGIONS_QUERY({ commit }, region) {
      return new Promise((resolve, reject) => {
        axios
          .get(`region/?region=${region}`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    PATCH_USER({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .patch('me/update', data)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
  },
  modules: {
    auth,
    request,
    manager,
    company,
    cards,
    cardsGpb,
    discounts,
    cardsFree,
    universities,
    faq,
    news,
    contacts,
    exportExcel,
  }
};

