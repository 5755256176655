<template>
  <nav class="menu" :class="{ 'menu--hide': $store.state.sidebar && !show && !$store.state.sidebarOpen, 'menu--show': show }">
    <div class="menu__item" v-for="item in getCurrentMenu()" :key="item.id">
      <router-link
        v-if="!item.submenu"
        :to="{ name: item.link }"
        class="menu__link"
        exact-active-class="menu__link--active"
        @click.native="removeCurrentLink"
      >
        <img
          :src="require('@/assets/img/sidebar/' + item.icon)"
          :alt="item.link"
          class="menu__icon"
        />
        <div class="menu__text">{{ item.name }}</div>
      </router-link>
      <div class="menu__toggler" v-if="item.submenu" @click="onArrowClick($event)">
        <img
          :src="require('@/assets/img/sidebar/' + item.icon)"
          :alt="item.link"
          class="menu__icon"
        />
        <div class="menu__text">{{ item.name }}</div>
        <div class="menu__arrow" v-if="item.submenu"></div>
      </div>
      <ul class="menu__submenu" v-if="item.submenu">
        <li v-for="(subItem, index) in item.submenu" :key="subItem.id">
          <div v-if="!subItem.link" class="menu__subitem" :class="{ active: index === 0 }" @click="fetchPartners(page, subItem.status, $store.state.admin.company.limit, $event)">{{ subItem.name }}</div>
          <div v-else-if="subItem.link === 'request'" class="menu__subitem" @click="fetchCards(page, subItem.status, $store.state.admin.request.limit, $event)">{{ subItem.name }}</div>
          <router-link
            :to="{name: subItem.link}"
            v-else
            class="menu__subitem"
            exact-active-class="menu__subitem--active"
          >
            {{ subItem.name }}
          </router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import {eventBus} from '@/main';

export default {
  name: 'Menu',
  props: ['show', 'mobileShow', 'regional'],
  data () {
    return {
      regionalAdminMenu: [
        {
          id: 1,
          name: 'Партнеры',
          link: '',
          icon: 'partners-icon.svg',
          submenu:
            [
              { id: 1.1, name: 'Активные', link: 'admin2', status: 2 },
              { id: 1.2, name: 'На проверке', link: 'admin4', status: 4 },
              { id: 1.3, name: 'Новые', link: 'admin', status: 1 },
              { id: 1.4, name: 'Архив', link: 'admin3', status: 3 },
            ]
        },
        {
          id: 2,
          name: 'Статистика',
          link: 'statistics',
          icon: 'stat.svg',
        },
      ],
      adminMenu: [
        {
          id: 1,
          name: 'Партнеры',
          link: '',
          icon: 'partners-icon.svg',
          submenu:
            [
              { id: 1.1, name: 'Активные', link: 'admin2', status: 2 },
              { id: 1.2, name: 'На проверке', link: 'admin4', status: 4 },
              { id: 1.3, name: 'Новые', link: 'admin', status: 1 },
              { id: 1.4, name: 'Архив', link: 'admin3', status: 3 },
            ]
        },
        { id: 2,
          name: 'Заявки',
          link: '',
          icon: 'news-icon.svg',
          submenu:
          [
            { id: 2.1, name: 'Оплачены', link: 'request', status: 2 },
            { id: 2.2, name: 'Подтверждены', link: 'request', status: 3 },
            { id: 2.4, name: 'Новые', link: 'request', status: 1 },
            { id: 2.3, name: 'Завершены', link: 'request', status: 4 },
            { id: 2.5, name: 'Архив', link: 'request', status: 5 },
          ]
        },
        {
          id: 3,
          name: 'Карты',
          link: 'cards',
          icon: 'credit-card-icon.svg',
        },
        {
          id: 4,
          name: 'Карты ГПБ',
          link: '',
          icon: 'gazprom-icon.svg',
          submenu:
            [
              { id: 4.1, name: 'Заявки', link: 'requests-gpb' },
              { id: 4.2, name: 'Мероприятия', link: 'events-gpb' },
              { id: 4.3, name: 'Список', link: 'cards-gpb' },
              { id: 4.4, name: 'Импорт', link: 'cards-gpb-import' },
              { id: 4.5, name: 'Университеты', link: 'universities' },
            ]
        },
        {
          id: 5,
          name: 'Свободные номера',
          link: '',
          icon: 'eyca-icon.svg',
          submenu:
            [
              { id: 5.1, name: 'Импорт', link: 'cards-free-import' },
              { id: 5.2, name: 'Экспорт', link: 'cards-free-export' },
            ]
        },
        {
          id: 6,
          name: 'Статистика',
          link: 'statistics',
          icon: 'stat.svg',
        },
        {
          id: 7,
          name: 'Пользователи',
          link: 'admin-users',
          icon: 'users-icon.svg',
        },
        {
          id: 8,
          name: 'Контент',
          link: '',
          icon: 'content-icon.svg',
          submenu:
            [
              { id: 8.1, name: 'FAQ', link: 'faq-list' },
              { id: 8.2, name: 'Новости', link: 'news-list' },
              { id: 8.3, name: 'Контакты', link: 'contacts-main' },
            ]
        },
        {
          id: 9,
          name: 'Экспорт',
          link: 'export',
          icon: 'file-text-icon.svg',
        },
      ],
      partnerMenu: [
        { id: 1, name: 'Организация', link: 'partner', icon: 'user-icon.svg' },
        { id: 2, name: 'Документы ', link: 'partner-docs', icon: 'docs-icon.svg' },
        { id: 3, name: 'Скидки', link: 'partner-discounts', icon: 'sale-icon.svg' },
        { id: 4, name: 'Адреса', link: 'partner-address', icon: 'file-text-icon.svg' },
        { id: 5, name: 'Проверка карт', link: 'partner-verify', icon: 'credit-card-icon.svg' },
        { id: 6, name: 'FAQ', link: 'partner-faq', icon: 'message-square-icon.svg'},
        { id: 7, name: 'Новости', link: 'partner-news', icon: 'news-icon.svg'},
        { id: 8, name: 'Контакты', link: 'partner-contacts', icon: 'content-icon.svg'},
        { id: 9, name: 'Отчеты', link: 'partner-report', icon: 'report-icon.svg'},
      ],
      page: 1,
    };
  },
  methods: {
    onArrowClick(event) {
      if (this.$route.name === 'admin') {
        this.removeCurrentLink();
      } else if (!event.currentTarget.classList.contains('is-show')) {
        const menuItems = document.getElementsByClassName('menu__subitem');
        menuItems.forEach((item) => {
          item.classList.remove('active');
        });
        this.removeCurrentLink();
      }
      event.currentTarget.classList.toggle('is-show');
      event.currentTarget.nextSibling.classList.toggle('is-show');
    },
    removeCurrentLink() {
      const menuToggler = document.getElementsByClassName('menu__toggler');
      const submenu = document.getElementsByClassName('menu__submenu');

      menuToggler.forEach((item) => {
        item.classList.remove('is-show');
      });
      submenu.forEach((item) => {
        item.classList.remove('is-show');
      });

      // this.$store.commit('sidebarMobileToggle');
    },
    getCurrentMenu() {
      if (localStorage.getItem('user') === 'admin') {
        if (!this.regional) return this.adminMenu;
        localStorage.setItem('adminType', 'regional');
        return this.regionalAdminMenu;
      } else {
        return this.partnerMenu;
      }
      //return localStorage.getItem('user') === 'admin' ? this.adminMenu : this.partnerMenu;
    },
    fetchCards(page, statusCount, limit, event) {
      const menuItems = document.getElementsByClassName('menu__subitem');

      menuItems.forEach((item) => {
        item.classList.remove('active');
      });

      event.currentTarget.classList.add('active');
      this.$store.commit('admin/request/changePage', 1);
      this.$store.commit('admin/request/changeStatusName', event.currentTarget.innerText);
      this.$store.commit('admin/request/changeStatus', statusCount);

      this.$store.dispatch('admin/request/GET_LIST', { page, statusCount, limit })
        .then(response => {
          this.$store.commit('admin/request/changeCount', response.data.pages);
          this.$store.commit('admin/request/setAll', response.data.count);
          this.$store.commit('admin/request/setLength', response.data.results.length);

          const path = 'request-list';
          if (this.$route.name !== path) this.$router.push({ name: path });
        })
        .catch(error => {
          this.$notify({
            type: 'warn',
            title: 'Внимание!',
            text: `Ошибка:${ error.message }`
          });
        });
    },
    fetchPartners(page, statusCount, limit, event, region = '', unregion = '') {
      const menuItems = document.getElementsByClassName('menu__subitem');

      menuItems.forEach((item) => {
        item.classList.remove('active');
      });

      eventBus.$emit('refreshFilter');

      event.currentTarget.classList.add('active');
      this.$store.commit('admin/company/changePage', 1);
      this.$store.commit('admin/company/changeStatusName', event.currentTarget.innerText);
      this.$store.commit('admin/company/changeStatus', statusCount);

      this.$store.dispatch('admin/company/GET_LIST', { page, statusCount, limit, region, unregion })
        .then(response => {
          this.$store.commit('admin/company/changeCount', response.data.pages);
          this.$store.commit('admin/company/setAll', response.data.count);
          this.$store.commit('admin/company/setLength', response.data.results.length);

          const path = 'admin';
          if (this.$route.name !== path) this.$router.push({ name: path });
        })
        .catch(error => {
          this.$notify({
            type: 'warn',
            title: 'Внимание!',
            text: `Ошибка:${ error.message }`
          });
        });
    },
  }
};
</script>

<style lang="sass">
@import "@/assets/common/index.scss"

.menu
  padding-top: 10px

.menu--hide
  .menu__text
    display: none

  .menu__arrow
    display: none

    &.is-show
      display: none

  .menu__submenu.is-show
    max-height: 0

.menu--show
  .menu__text
    display: block

  .menu__arrow
    display: block

  .menu__submenu
    max-height: 150px

    &:not(.is-show)
      max-height: 0

.menu__item
  position: relative

.menu__link
  display: flex
  align-items: center
  padding: 11px 32px
  border-left: 3px solid transparent
  color: #323f4b
  text-decoration: none
  transition: color 0.3s

  &:hover
    color: $color-theme

.menu__link--active
  border-left-color: $color-theme
  background-color: #f1f8fe

  .menu__text
    color: $color-theme
    font-weight: 600

.menu__toggler
  display: flex
  align-items: center
  padding: 11px 32px
  border-left: 3px solid transparent
  color: #323f4b
  text-decoration: none
  cursor: pointer
  transition: color 0.3s

  &:hover
    color: $color-theme

  &.is-show
    .menu__arrow
      transform: rotate(180deg)

.menu__link--active
  border-left-color: $color-theme
  background-color: #f1f8fe

  .menu__text
    color: $color-theme
    font-weight: 600

.menu__icon
  margin-right: 18px

.menu__text
  font-size: 16px
  white-space: nowrap

.menu__submenu
  max-height: 0
  padding-left: 95px
  overflow: hidden
  transition: max-height 0.3s

  &.is-show
    max-height: 165px

  li
    position: relative
    margin-bottom: 10px

    &::before
      content: ''
      position: absolute
      top: 8px
      left: -16px
      width: 7px
      height: 7px
      border-radius: 50%
      background-color: $color-theme

.menu__subitem
  font-size: 16px
  color: $color-text-base
  text-decoration: none
  cursor: pointer
  transition: color 0.3s

  &.active
    color: $color-theme

  &:hover
    color: $color-theme

.menu__subitem--active
  color: $color-theme

.menu__arrow
  position: absolute
  top: 0
  right: 0
  width: 52px
  height: 52px
  background-image: url("../../assets/img/sidebar/arrow-bottom.svg")
  background-repeat: no-repeat
  background-position: center
  background-size: 11px
  pointer-events: none
  transition: transform 0.3s

</style>
