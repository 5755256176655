import axios from '@/http/axios/axiosAdmin';

export default {
  namespaced: true,
  actions: {
    GET_LIST({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`card-gpb/?page=${data.page}&page_size=${data.limit}`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_REQUESTS({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`card-gpb/request/?page=${data.page}&page_size=${data.page_size}`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_REQUEST({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`card-gpb/request/${id}/`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_EVENTS({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`card-gpb/event/?page=${data.page}&page_size=${data.page_size}`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_EVENT({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`card-gpb/event/${id}/`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_EXPORT() {
      return new Promise((resolve, reject) => {
        axios
          .get('card-gpb/export/request/')
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    PATCH_REQUEST({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`card-gpb/request/${payload.id}/`, payload.form)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    PATCH_EVENT({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`card-gpb/event/${payload.id}/`, payload.form)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    DELETE_REQUEST({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`card-gpb/request/${id}/`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    DELETE_EVENT({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`card-gpb/event/${id}/`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_IMPORT({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`card-gpb/log?page=${data.page}&page_size=${data.limit}`)
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_QUERY({ commit }, query) {
      return new Promise((resolve, reject) => {
        axios
          .get(`card-gpb/?query=${query}`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_DIFF() {
      return axios.get('card-gpb/diff/')
    },
  }
};