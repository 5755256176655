import Vue from 'vue';
import Vuex from 'vuex';
import axiosAdmin from '@/http/axios/axiosAdmin';
import axiosPartner from '@/http/axios/axiosPartner';
import partner from './partner';
import admin from './admin';
import overall from './overall';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    sidebar: false,
    sidebarOpen: false,
    regionalAdmin: false,
  },
  mutations: {
    sidebarToggle(state) {
      state.sidebar = !state.sidebar;
    },
    sidebarMobileToggle(state) {
      state.sidebarOpen = !state.sidebarOpen;
    },
    sidebarMobileHide(state) {
      state.sidebarOpen = false;
    },
    setRegionalAdmin(state, payload) {
      state.regionalAdmin = payload;
    },
  },
  actions: {
    GET_USER({ commit }, user) {
      return new Promise((resolve, reject) => {
        if (user === 'admin') {
          axiosAdmin
            .get('me/')
            .then(response => resolve(response))
            .catch(error => {
              reject(error);
            });
        } else if (user === 'partner') {
          axiosPartner
            .get('me/')
            .then(response => resolve(response))
            .catch(error => {
              reject(error);
            });
        }
      });
    },
  },
  modules: {
    partner,
    admin,
    overall
  }
});
