<template>
  <router-view />
</template>

<script>
import jwt from '@/http/requests/partner/auth';

export default {
  name: 'Partner',
  async created () {
    jwt.init();
  }
};
</script>