<template>
  <div class="empty">
    <a href="https://mkp-ruy.ru/" target="_blank" class="empty__link">
      <img src="@/assets/img/empty-logo.svg" alt="MKP logo">
    </a>
    <div class="empty__container">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
};
</script>

<style lang="sass">
@import "@/assets/common/index.scss"

.empty
  position: relative

  &:before
    content: ""
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 329px
    background-image: url("../assets/img/empty-bg.svg")
    background-repeat: no-repeat
    background-position: center
    background-size: cover
    z-index: -1

.empty__container
  max-width: 1580px
  margin: 0 auto
  padding-top: 160px

.empty__link
  position: absolute
  left: 50%
  top: 80px
  display: block
  transform: translateX(-50%)
  line-height: 0
  transition: opacity 0.3s

  &:hover
    opacity: 0.8

  +max-w($mobile_sm)
    width: 60%
</style>
