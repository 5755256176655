import axios from '@/http/axios/axiosAdmin';

export default {
  namespaced: true,
  actions: {
    GET_LIST({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`card-gpb/uni/?page=${data.page}&page_size=${data.limit}&unregion=${data.unregion}&region=${data.region}`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    POST_DATA({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('card-gpb/uni/', payload)
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
    PATCH_DATA({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`card-gpb/uni/${payload.id}/`, payload)
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_QUERY({ commit }, query) {
      return new Promise((resolve, reject) => {
        axios
          .get(`card-gpb/uni/?query=${query}`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
  }
};