import axios from '@/http/axios/axiosAdmin';

export default {
  namespaced: true,
  state: () => ({
    companies: [],
    page: 1,
    count: 1,
    limit: 20,
    all: 0,
    length: 0,
    status: '1',
    statusName: 'Новые',
    discountLimit: 20,
    verifyLimit: 20,
  }),
  mutations: {
    getList(state, payload) {
      state.companies = payload;
    },
    changePage(state, payload) {
      state.page = payload;
    },
    changeCount(state, payload) {
      state.count = payload;
    },
    changeLimit(state, payload) {
      state.limit = payload;
    },
    setAll(state, payload) {
      state.all = payload;
    },
    setLength(state, payload) {
      state.length = payload;
    },
    changeStatus(state, payload) {
      state.status = payload;
    },
    changeStatusName(state, payload) {
      state.statusName = payload;
    },
    changeDiscountLimit(state, payload) {
      state.discountLimit = payload;
    },
    changeVerifyLimit(state, payload) {
      state.verifyLimit = payload;
    },
  },
  actions: {
    GET_LIST({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`partner/?page_size=${data.limit}&page=${data.page}&status=${data.statusCount}&ordering=${data.ordering}&unregion=${data.unregion}&region=${data.region}`)
          .then(response => {
            commit('getList', response.data.results);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_DATA({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`partner/${id}/profile/`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    POST_DATA({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('partner/create', payload)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    PATCH_DATA({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`partner/${payload.id}/profile/update/`, payload.data)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_CATEGORIES() {
      return new Promise((resolve, reject) => {
        axios
          .get('partner/category/')
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_STATUS() {
      return new Promise((resolve, reject) => {
        axios
          .get('partner/status/')
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_PAYMENT() {
      return new Promise((resolve, reject) => {
        axios
          .get('partner/payment-type/')
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_SERVICE() {
      return new Promise((resolve, reject) => {
        axios
          .get('partner/service-type/')
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    SEARCH_ADDRESS({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`find-adds/?q=${data.search}`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    POST_ADDRESS({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`partner/${payload.id}/address/`, payload.data)
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
    DELETE_ADDRESS({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`partner/${payload.id}/address/${payload.address}`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_PERSON({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`partner/${id}/user/`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    PATCH_PERSON({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`partner/${payload.id}/user/update/`, payload.data)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_ADDRESS_LIST({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`partner/${id}/address/`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_ADDRESS_DATA({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/partner/${payload.id}/address/${payload.address}/`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    PATCH_ADDRESS_DATA({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/partner/${payload.id}/address/${payload.address}/`, payload.data)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_QUERY({ commit }, query) {
      return new Promise((resolve, reject) => {
        axios
          .get(`partner/?query=${query}`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_DISCOUNT_LOG({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`partner/${payload.id}/discount-log/?page_size=${payload.limit}&page=${payload.page}`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GET_VERIFY_LOG({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`partner/${payload.id}/token-log/?page_size=${payload.limit}&page=${payload.page}`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
  }
};